export const ClusterModul = [
  {
    id: "1",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "2",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "3",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "4",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "5",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "6",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "7",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
  {
    id: "8",
    workload: "fiverr-v2",
    cpu: "1549.40",
    cpuPerc: 40,
    gib: "1549.40",
    gibPerc: 40,
    cpuReq1: "2.90 ",
    cpuReq2: "1.15",
    memory1: "2000 MB",
    memory2: "1200 MB",
    savings: "$1,419.20",
  },
];
